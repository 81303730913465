// This allows plugins that are loaded afterward to attach to this jQuery, rather than overwriting
// it with their own

const tippy = require('tippy.js');

window.$ = window.jQuery = require('jquery');

define('sign_in', ['Exclusive/SignInTips', 'Flash'], function(SignInTips, Flash) {
  SignInTips.init();

  const $flashMessage = $('#flash');

  if ($flashMessage.length > 0) {
    Flash.init();
  }
});

/**
 * Set tippy.js default styles
 */
tippy.default.setDefaults({
  animateFill: false,
  arrow: true,
  hideOnClick: 'toggle',
  interactive: true,
  theme: 'white'
});
