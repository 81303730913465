define([], function() {
  function init() {
    var sign_in_tips = document.getElementsByClassName('js_sign_in_tips')[0];
    var forgot_password_trigger = document.getElementsByClassName('js_forgot_password_trigger')[0];
    var js_forgot_password = document.getElementsByClassName('js_forgot_password')[0];

    if (sign_in_tips.classList.contains('hide')) {
      setInterval(function() {
        if (document.getElementById('flash')) {
          sign_in_tips.classList.remove('hide');
        }
      }, 200)
    }

    if (forgot_password_trigger) {
      document.addEventListener('click', function(e) {
        if (e.target.classList.contains('js_forgot_password_trigger')) {
          if (js_forgot_password.offsetParent !== null) {
            hidePasswordHint(e, js_forgot_password);
          }
          else {
            js_forgot_password.style.display = 'block'
          }
          e.preventDefault();
        }
        else if (!(e.target.classList.contains('js_forgot_password'))) {
          hidePasswordHint(e, js_forgot_password);
        }
      });
    }

    document.addEventListener('keypress', function(e) {
      var caps_warning = document.getElementById('caps_warning');

      if (['student_password', 'staffer_password'].indexOf(e.target.getAttribute('id')) >= 0) {
        e = e || event;
        var charKeyCode = e.keyCode ? e.keyCode : e.which;
        // To work with both I.E & Gecko . e.keycode:: I.E & e.which :: Gecko.

        var shiftKey = e.shiftKey ? e.shiftKey : ((charKeyCode == 16) ? true : false);
        // To work with both I.E & Gecko. e.shiftKey :: I.E & charKeyCode == 16 :: Gecko.

        // Check both the conditions as described above
        if ((charKeyCode >= 65 && charKeyCode <= 90) && !shiftKey) {
          // Caps lock is on
          caps_warning.style.display = 'block';
        }
        else if ((charKeyCode >= 97 && charKeyCode <= 122) && shiftKey) {
          // Caps lock is on
          caps_warning.style.display = 'block';
        }
        else {
          caps_warning.style.display = 'none';
        }
      }
    });
  }

  function hidePasswordHint(e, el) {
    el.style.display = 'none';
  }

  return {
    init: init
  }
});