define([], function Flash(){

    function init(){
        $('#flash p').append('<i class="fa fa-times pos_fr cursor_point" aria-hidden="true"></i>')
        $('#flash .fa-times').click(function () { $('#flash').hide(); })
    }

    return {
        init: init
    }
});